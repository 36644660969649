export const locKeys = new Map([
  ["beach", "themeSelector.affinity.beach"],
  ["family", "themeSelector.affinity.family"],
  ["romantic", "themeSelector.affinity.romantic"],
  ["mountain", "themeSelector.affinity.moutain"],
  ["historic", "themeSelector.affinity.historic"],
  ["spa", "themeSelector.affinity.spa"],
  ["golf", "themeSelector.affinity.golf"],
  ["casino", "themeSelector.affinity.casino"],
  ["waterpark", "themeSelector.affinity.waterpark"],
]);
